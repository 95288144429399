import axios from "axios";
export const siteService = {
  createSite,
  updateSitePolygonMarker,
  updateSite
};
const API = process.env.REACT_APP_API_URL;

async function createSite(data,token) {
  return await axios.post(`${API}/sites/store`, data, {
    headers: { 'Content-Type': 'multipart/form-data', Authorization: "Bearer " + token },
  });
}

async function updateSitePolygonMarker(data,token) {
  return await axios.post(`${API}/sites/update-sites-polygon-marker`, data, {
    headers: { Authorization: "Bearer " + token },
  });
}

async function updateSite(data,token) {
  return await axios.post(`${API}/sites/update`, data, {
    headers: { 'Content-Type': 'multipart/form-data', Authorization: "Bearer " + token },
  });
}

