import axios from "axios";
export const miscellaneousService = {
  getCategory,
  getBuildingTypes,
  getStructures
};
const API = process.env.REACT_APP_API_URL;

async function getCategory(token) {
  try {
    const response = await axios.get(`${API}/categories`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return response?.data?.data;
  } catch (error) {
    console.error("Error while making GET request:", error);
    throw error;
  }
}

async function getBuildingTypes(token) {
  try {
    const response = await axios.get(`${API}/buildings/types/get-building-type`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return response?.data?.data;
  } catch (error) {
    console.error("Error while making GET request:", error);
    throw error;
  }
}

async function getStructures(token) {
  try {
    const response = await axios.get(`${API}/structures/all`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return response?.data?.data;
  } catch (error) {
    console.error("Error while making GET request:", error);
    throw error;
  }
}
