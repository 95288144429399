/* eslint-disable jsx-a11y/alt-text */
import {useState } from "react";
import ProjectListing from "./TabsAndMenus/ProjectListing";
import StructureList from "./StructureList";
const SideControlPanel = ({ leftToggleCollapse, openModal }) => {
  const [activeTab, setActiveTab] = useState(1);
  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };
  return (
    <div className="sidebar__section" id="sidebar-section">
      <div className="side__nav">
        <ul>
          <li>
            <div onClick={() => handleTabClick(1)}>
              <img src="./images/sidebar-icons/icon-2.svg" />
              <label>Layouts</label>
            </div>
          </li>
          <li>
            <div onClick={() => handleTabClick(2)}>
              <img src="./images/sidebar-icons/icon-3.svg" />
              <label>Structures</label>
            </div>
          </li>
          <li>
            <div onClick={() => handleTabClick(3)}>
              <img src="./images/sidebar-icons/icon-4.svg" />
              <label>Cables</label>
            </div>
          </li>
          <li>
            <div onClick={() => handleTabClick(4)}>
              <img
                src="./images/sidebar-icons/icon-5.svg"
                id="rack-loder-xdcs"
              />
              <label>Racks</label>
            </div>
          </li>
          <li>
            <div id="add-svg-button">
              <img src="./images/sidebar-icons/icon-6.svg" />
              <label>Objects</label>
            </div>
          </li>
        </ul>
      </div>
      <div className="side__tab">
        <div className="collapse-icon" onClick={leftToggleCollapse}>
          <i className="ri-arrow-left-double-line ri-lg"></i>
        </div>
        <div className="left__sidebar__content">
          <div className="tab__content__wrapper">
            {activeTab === 1 && <ProjectListing />}
            {activeTab === 2 && (
              <div className="side__tab__listing__section">
                <div className="side__tab__list__header__inner">
                  <h5>Structures</h5>
                </div>
                <StructureList />
              </div>
            )}
            {activeTab === 3 && (
              <div className="side__tab__listing__section">
                <div className="side__tab__list__header__inner">
                  <h5>Cables</h5>
                </div>
              </div>
            )}
            {activeTab === 4 && (
              <div className="side__tab__listing__section">
                <div className="side__tab__list__header__inner">
                  <h5>Free Standing Cabinets</h5>
                </div>
                <div className="gird--item--section">
                  <div className="gird--item">
                    <div className="gird--item-content">
                      <img src="./images/cabinet.png" />
                      <h6>33U_FCabinet</h6>
                    </div>
                  </div>
                  <div className="gird--item">
                    <div className="gird--item-content">
                      <img src="./images/cabinet.png" />
                      <h6>33U_FCabinet</h6>
                    </div>
                  </div>
                  <div className="gird--item">
                    <div className="gird--item-content">
                      <img src="./images/cabinet.png" />
                      <h6>33U_FCabinet</h6>
                    </div>
                  </div>
                  <div className="gird--item">
                    <div className="gird--item-content">
                      <img src="./images/cabinet.png" />
                      <h6>33U_FCabinet</h6>
                    </div>
                  </div>
                </div>
                <div className="side__tab__list__header__inner">
                  <h5>Rack Contruction</h5>
                </div>
                <div className="gird--item--section">
                  <div className="gird--item">
                    <div
                      className="gird--item-content active--content"
                      onClick={openModal}
                    >
                      <img src="./images/rack.png" />
                      <h6>0-48U Rack</h6>
                    </div>
                  </div>
                  <div className="gird--item">
                    <div className="gird--item-content">
                      <img src="./images/rack.png" />
                      <h6>0-48U Rack</h6>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideControlPanel;
