import axios from "axios";
export const projectService = {
  getProjects,
  createProject,
  deleteProject,
  updateProject
};
const API = process.env.REACT_APP_API_URL;

async function createProject(data,token) {
  return await axios.post(`${API}/projects/store`, data, {
    headers: { 'Content-Type': 'multipart/form-data', Authorization: "Bearer " + token },
  });
}
async function updateProject(data,token) {
  return await axios.post(`${API}/projects/update`, data, {
    headers: { 'Content-Type': 'multipart/form-data', Authorization: "Bearer " + token },
  });
}

async function getProjects(categoryName, token) {
  try {
    const response = await axios.get(`${API}/projects/get-all`, {
      params: {
        category_name: categoryName,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    });
    return response?.data?.data;
  } catch (error) {
    console.error("Error while making GET request:", error);
    throw error;
  }
}
async function deleteProject(project_id, token) {
  try {
    const response = await axios.post(
      `${API}/projects/delete-project`, 
      { project_id },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error while deleting project:", error);
    throw error;
  }
}
