import authSlice from "./auth";
import loadingSlice from "./loading";
import setupSlice from "./setup";
import { combineReducers } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
  auth: authSlice,
  loading: loadingSlice,
  setup: setupSlice,
});

export default rootReducer;
