/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Avatar from "react-avatar";
import { useDispatch } from "react-redux";
import {
  clearSetup,
  getActiveMarker,
  getActiveProject,
  getCurrentMode,
  getSelectedCategory,
  setCurrentMode,
  updateBuilding,
} from "../store/slices/setup";
import { getAuth, getToken, logout } from "../store/slices/auth";
import { useNavigate } from "react-router-dom";
import { useViewer } from "../context/ViewerContext";
import { buildingService } from "../_services";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { isEmptyObject, MODE } from "../_helpers";
import { useSetupContext } from "../context/SetupContext";

const TopNavBar = () => {
  const activeMarker = useSelector(getActiveMarker);
  const user = useSelector(getAuth);
  const project = useSelector(getActiveProject);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(getToken);
  const { saveCanvasState } = useViewer();
  const [isVisible, setIsVisible] = useState(false);
  const setupMode = useSelector(getCurrentMode);
  const {setShowProjectForm,setIsSetupModalVisible,handleToggleToProjectListingMode} = useSetupContext()
  const category = useSelector(getSelectedCategory);
  const handelLogout = () => {
    const confirmation = window.confirm("Are you sure you want to logout?");
    if (confirmation) {
      dispatch(logout());
      dispatch(clearSetup());
      navigate("/");
    }
  };

  const handleToggle = () => {
    setIsVisible(!isVisible);
  };
  const toggleDropdown = (event) => {
    const dropdownContent = event.currentTarget.nextElementSibling;
    if (dropdownContent) {
      dropdownContent.classList.toggle("show");
    }
  };
  const handelSaveCanvas = async () => {
    try {

      const data = saveCanvasState();
      let parsedData = JSON.parse(data);
      parsedData.layers[0].shapes = parsedData.layers[0].shapes.filter(
        (shape) => shape.type !== "image"
      );

      const polygonIds = parsedData.layers[0].shapes
        .filter((shape) => shape.type === "polygon")
        .map((polygon) => polygon.id);

      if (polygonIds.length <= 0) {
        toast.error(
          "Please draw at least one polygon on the building to mark the floor."
        );
        return;
      }

      const response = await buildingService.updateBuildingFloorMarkingLayers(
        {
          floor_marking_layers: JSON.stringify(parsedData),
          building_id: activeMarker?.id,
          floor_polygon_ids: polygonIds,
        },
        token
      );

      let building = response?.data?.data;
      dispatch(
        updateBuilding({
          building: building,
          site: { id: building?.site_id, project_id: project[0]?.id },
        })
      );
      toast.success("Floor marked successfully");
    } catch (error) {
      console.error("Error saving canvas state:", error);
    }
  };

  return (
    <>
      <a className="navbar-brand" href="#">
        <img className="logo" width="250" src="./images/globe.png" alt="Logo" />
      </a>
      <div className="navbar-wrapper">
        <nav className="navbar">
          <ul className="nav--list">
            <li
              onClick={() => {
                if(isEmptyObject(category)){return toast.error("Please select a category")}
                handleToggleToProjectListingMode();
                setIsSetupModalVisible(true)
                setShowProjectForm(true);
                dispatch(
                  setCurrentMode({
                    mode: MODE.INITIAL_POJECT_SELECTION,
                    viewer: MODE.INITIAL_STAGE,
                    props: {
                      prevMode: setupMode?.mode,
                      previewImage: { show: false, imageObject: {} },
                    },
                  })
                );
              }}
            >
              Create Project
            </li>
            <li>
              <a href="#">Manage</a>
            </li>
            <li>
              <a href="#">View</a>
            </li>
            <li>
              <a href="#">Import</a>
            </li>
            <li>
              <a href="#">Export</a>
            </li>
            <li>
              <a href="#">Certify</a>
            </li>
            <li>
              <a href="#">Labeling</a>
            </li>
            <li>
              <a href="#">Reporting</a>
            </li>
            <li>
              <a href="#">Analysis</a>
            </li>
            <li>
              <a href="#">Help</a>
            </li>
            <li>
              <a href="#" onClick={handleToggle}>
                <i className="ri-more-2-fill ri-xl"></i>
              </a>
            </li>
          </ul>
          <div className="right-section">
            <i className="ri-settings-5-line ri-xl"></i>
            <div className="dropdown-section notification-section">
              <div className="dropdown-item" onClick={toggleDropdown}>
                <i className="ri-notification-3-line ri-xl"></i>
              </div>
              <div className="dropdown-content">
                <h4>Notification</h4>
                <ul>
                  <li>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                    <span>11 July, 2024 | 3:00 PM</span>
                  </li>
                  <li>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                    <span>11 July, 2024 | 3:00 PM</span>
                  </li>
                  <li>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                    <span>11 July, 2024 | 3:00 PM</span>
                  </li>
                </ul>
              </div>
            </div>
            <i onClick={handelLogout} className="ri-shut-down-line ri-xl"></i>
            <div className="dropdown-section">
              <div className="dropdown-item" onClick={toggleDropdown}>
                <Avatar name={user?.user} size="40" round />
              </div>
              <div className="dropdown-content">
                <p>{user?.user}</p>
                <i>{user?.email}</i>
                <hr />
                <span onClick={handelLogout}>
                  <i className="ri-shut-down-line ri-xl"></i> Logout
                </span>
              </div>
            </div>
          </div>
        </nav>
        <div className="nav-bottom-bar">
          <ul>
            <li>
              <span onClick={handelSaveCanvas}>Save Floor Plan</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default TopNavBar;
