/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import TopNavBar from "../TopNavBar";
import SideControlPanel from "../SideControlPanel";
import { ViewerProvider } from "../../context/ViewerContext";
import { useSelector } from "react-redux";
import SiteModal from "../SiteModal";
import { SetupProvider } from "../../context/SetupContext";
import { getCanOpenLeftSidePanel } from "../../store/slices/setup";
import { MODE } from "../../_helpers";
import DragNDropHolder from "./DragNDropHolder";
const SiteLayout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isRightCollapsed, setIsRightCollapsed] = useState(true);
  const canOpenLeftSidePanel = useSelector(getCanOpenLeftSidePanel);
  const leftToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const rightToggleCollapse = () => {
    setIsRightCollapsed(!isRightCollapsed);
  };

  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    if (canOpenLeftSidePanel === MODE.NO) {
      setIsCollapsed(true);
      return;
    }
    if (canOpenLeftSidePanel === MODE.YES) {
      setIsCollapsed(false);
      return;
    }
  }, [canOpenLeftSidePanel]);
  
  return (
    <SetupProvider>
      <ViewerProvider>
        <DragNDropHolder>
        <div className="main__wrapper">
          <header className="top__bar site__layout" id="top-header">
            <TopNavBar />
          </header>
          <div
            className={`main__section ${isCollapsed ? "left__collapsed" : ""}`}
          >
            <div
              className="sidebar__wrapper"
              id="sidebar-control-panel-wrapper"
            >
              <SideControlPanel
                leftToggleCollapse={leftToggleCollapse}
                openModal={openModal}
                isCollapsed={isCollapsed}
              />
            </div>
            <div className="main__container__wrapper">
              <div className="main__container">
                <div className="canvas__container">
                  <div className="viewer">{children}</div>
                  <SiteModal
                    headerTitle="Acme Building"
                    isOpen={isOpen}
                    onClose={closeModal}
                  >
                    <div className="site__modal__inner__content">
                      <div className="controls__button">
                        <button>
                          <i className="ri-zoom-in-line ri-xl"></i>
                        </button>
                        <button>
                          <i className="ri-zoom-out-line ri-xl"></i>
                        </button>
                      </div>
                      <img
                        className="rack__image"
                        src="./images/rack--large.png"
                      />
                    </div>
                  </SiteModal>
                </div>
              </div>
            </div>
            <div
              className={`right__sidebar__wrapper ${
                isRightCollapsed ? "right__collapsed" : ""
              }`}
            >
              <div className="collapse-icon" onClick={rightToggleCollapse}>
                <i className="ri-arrow-right-double-line ri-lg"></i>
              </div>
              <div className="right__sidebar__content"></div>
            </div>
          </div>
        </div>
        </DragNDropHolder>
      </ViewerProvider>
    </SetupProvider>
  );
};

export default SiteLayout;
