/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../store/slices/auth";
import { Oval } from "react-loader-spinner";
import {
  initMode,
  MODE,
  Roles,
  showSuccessToast,
  showValidationErrorsToast,
} from "../_helpers";
import { authService } from "../_services";
import {
  setCanOpenLeftSidePanel,
  setCurrentMode
} from "../store/slices/setup";
const SignInPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setLoading] = React.useState(false);
  const schema = yup.object().shape({
    email: yup.string().required("Please Enter Your email or Email Address"),
    password: yup.string().required("Password is required"),
    remember: false,
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const response = await authService.login(values);
        // showSuccessToast(response?.data?.message);
        dispatch(login(response.data.data));
        await new Promise((resolve) => setTimeout(resolve, 2000));
        if (response?.data?.data?.role?.length === 0) {
          navigate("/select-role");
          return;
        }
        if (response?.data?.data?.role?.includes(Roles.END_USER)) {
          alert("NOT ACTIVE YET");
          return;
        }
        
        if (response?.data?.data?.role?.includes(Roles.INSTALLER)) {
          dispatch(setCurrentMode(initMode));
          navigate("/viewer");

          return;
        }
        //TODO:: We check this case later if required
        // if (authRole.includes(Roles.END_USER) || authRole.includes(Roles.INSTALLER)) {
        //     navigate('/viewer');
        //     return;
        // }
      } catch (error) {
        showValidationErrorsToast(error);
        console.error(error, "error");
      } finally {
        setLoading(false);
      }
    },
  });
  const handleCheckboxChange = (e) => {
    formik.setFieldValue("remember", e.target.checked);
  };

  return (
    <>
      <header>
        <nav className="navbar">
          <a className="navbar-brand" href="#">
            <img width="250" src="./images/logo.svg" />
          </a>
        </nav>
      </header>
      <section className="login-section">
        <div className="login-container">
          <h1>Sign In</h1>
          <p>Welcome back! Please enter your details.</p>
          <form onSubmit={formik.handleSubmit}>
            <div className="login-form-section">
              <div className="form__group">
                <span className="text-danger">
                  {formik.touched.email && formik.errors.email}
                </span>
                <input
                  type="text"
                  className="form__control"
                  placeholder="User Name or Email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
              </div>
              <div className="form__group">
                <span className="text-danger">
                  {formik.touched.password && formik.errors.password}
                </span>
                <div className="form__password">
                  <input
                    type="password"
                    name="password"
                    className="form__control"
                    placeholder="Password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  <span className="form__control__icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M17.8827 19.2968C16.1814 20.3755 14.1638 21.0002 12.0003 21.0002C6.60812 21.0002 2.12215 17.1204 1.18164 12.0002C1.61832 9.62282 2.81932 7.5129 4.52047 5.93457L1.39366 2.80777L2.80788 1.39355L22.6069 21.1925L21.1927 22.6068L17.8827 19.2968ZM5.9356 7.3497C4.60673 8.56015 3.6378 10.1672 3.22278 12.0002C4.14022 16.0521 7.7646 19.0002 12.0003 19.0002C13.5997 19.0002 15.112 18.5798 16.4243 17.8384L14.396 15.8101C13.7023 16.2472 12.8808 16.5002 12.0003 16.5002C9.51498 16.5002 7.50026 14.4854 7.50026 12.0002C7.50026 11.1196 7.75317 10.2981 8.19031 9.60442L5.9356 7.3497ZM12.9139 14.328L9.67246 11.0866C9.5613 11.3696 9.50026 11.6777 9.50026 12.0002C9.50026 13.3809 10.6196 14.5002 12.0003 14.5002C12.3227 14.5002 12.6309 14.4391 12.9139 14.328ZM20.8068 16.5925L19.376 15.1617C20.0319 14.2268 20.5154 13.1586 20.7777 12.0002C19.8603 7.94818 16.2359 5.00016 12.0003 5.00016C11.1544 5.00016 10.3329 5.11773 9.55249 5.33818L7.97446 3.76015C9.22127 3.26959 10.5793 3.00016 12.0003 3.00016C17.3924 3.00016 21.8784 6.87992 22.8189 12.0002C22.5067 13.6998 21.8038 15.2628 20.8068 16.5925ZM11.7229 7.50857C11.8146 7.50299 11.9071 7.50016 12.0003 7.50016C14.4855 7.50016 16.5003 9.51488 16.5003 12.0002C16.5003 12.0933 16.4974 12.1858 16.4919 12.2775L11.7229 7.50857Z"></path>
                    </svg>
                  </span>
                </div>
              </div>
              <div className="form__group__inline">
                <div className="form__checkbox">
                  <input
                    type="checkbox"
                    name="remember"
                    checked={formik.values.remember}
                    onChange={handleCheckboxChange}
                    onBlur={formik.handleBlur}
                  />
                  <label htmlFor="vehicle">Remember</label>
                </div>
                <a className="forget__password" href="">
                  <b>Forget Password</b>
                </a>
              </div>
              <button
                type="submit"
                name="submit"
                className="btn__primary"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Oval
                    visible={true}
                    height="30"
                    width="30"
                    color="#FFF"
                    ariaLabel="oval-loading"
                  />
                ) : (
                  "Sign in"
                )}
              </button>
              <Link to={"/sign-up"}>Create Account</Link>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default SignInPage;
