/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getToken, setRole } from "../store/slices/auth";
import { getLoading, setLoading } from "../store/slices/loading";
import RadioCard from "../components/RadioCard";
import { authService } from "../_services";
import { Roles, showValidationErrorsToast } from "../_helpers";
const SelectRole = () => {
    const dispatch = useDispatch()
    const token = useSelector(getToken)
    const isLoading = useSelector(getLoading)
    console.log("Max Screen",isLoading)
    const handleOptionChange = async (event) => {
        try {
            const response = await authService.assingRole({ role: event.target.value }, token);
            if (response.data.code === 200) {
                dispatch(setRole({ role: event.target.value }));
                if (event.target.value === Roles.END_USER) {
                    //TODO:: Later we need to update this
                }
                if (event.target.value === Roles.INSTALLER) {
                    dispatch(setLoading({ isLoading: true, route: '/viewer' }));
                }
            }
        } catch (error) {
            showValidationErrorsToast(error);
            console.log(error);
        }
    };


    return (
        <>
            <header>
                <nav className="navbar">
                    <a className="navbar-brand" href="#"><img width="250" src="./images/logo.svg" /></a>
                </nav>
            </header>

            <section className="login-section">
                <div className="login-container">
                    <h1>Who Are You?</h1>
                    <p>Are you an Installer or an End User?</p>
                    <div className="login-form-section">
                        <div className="grid">
                            <RadioCard
                                name="plan"
                                value="installer"
                                onChange={handleOptionChange}
                                imgSrc="./images/Installer.png"
                                altText="Installer"
                                label="Installer"
                            />
                            <RadioCard
                                name="plan"
                                value="end_user"
                                onChange={handleOptionChange}
                                imgSrc="./images/End-User.png"
                                altText="End User"
                                label="End User"
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SelectRole;
