import React from "react";

const RadioCard = ({
  name,
  value,
  onChange,
  imgSrc,
  altText,
  label,
  action = false,
  onEdit,
  onDelete,
}) => {
  const handleEditClick = (event) => {
    event.stopPropagation();
    event.preventDefault(); 
    if (onEdit) {
      onEdit();
    }
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    event.preventDefault(); 
    if (onDelete) {
      console.log("delete");
      onDelete();
    }
  };

  return (
    <label className="card">
      {action && (
        <div
         className="action-buttons"
        >
          <i
            className="ri-edit-line action-hover"
            style={{ cursor: "pointer" }}
            onClick={handleEditClick}
          ></i>
          <i
            className="ri-delete-bin-line action-hover"
            style={{ cursor: "pointer" }}
            onClick={handleDeleteClick}
          ></i>
        </div>
      )}
      <input
        name={name}
        className="radio"
        type="radio"
        value={value}
        onChange={onChange}
      />
      <span className="plan-details" style={{ position: "relative" }}>
        <div className="checkbox--icon"></div>
        <img src={imgSrc || './images/no-image-available.png'} alt={altText} />
        <p>{label}</p>
      </span>
    </label>
  );
};

export default RadioCard;
