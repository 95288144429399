export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};
export const dropAndReturnElementById = ({ id, elements }) => {
  const currentElement = elements.find(({ id: elementId }) => elementId === id);

  const elementsWithoutCurrent = elements.filter(
    ({ id: elementId }) => id !== elementId
  );

  return [elementsWithoutCurrent, currentElement];
};
export const dropAndReturnMarkerById = ({ id, elements }) => {
  console.log( id, elements,"JOJO")
  const currentElement = elements.find(({ site_id: elementId }) => elementId === id);

  const elementsWithoutCurrent = elements.filter(
    ({ site_id: elementId }) => id !== elementId
  );

  return [elementsWithoutCurrent, currentElement];
};

export const coordinateFuncsToCoordinates = (coordinates) =>
  coordinates.map(({ lat, lng }) => ({
    lat: lat(),
    lng: lng(),
  }));
export const dropAndReturnLastElement = (elements) => {
  if (elements.length === 0) {
    throw new Error("The array is empty");
  }

  const lastElement = elements[elements.length - 1];
  const elementsWithoutLast = elements.slice(0, -1);

  return [elementsWithoutLast, lastElement];
};
const getVertexWithChangedCoordinates = ({
  prevCoordinates,
  currentCoordinates,
}) =>
  currentCoordinates.find(
    ({ lat: currLat, lng: currLng }) =>
      !prevCoordinates.some(
        ({ lat: prevLat, lng: prevLng }) =>
          prevLat === currLat && prevLng === currLng
      )
  );

export const processOnVertexOrEdgeClick = ({
  event,
  prevCoordinates,
  currentCoordinates,
}) => {
  const isClickedOnEdgeOrVertex =
    event.edge !== undefined || event.vertex !== undefined;

  const vertexWithChangedCoordinates = isClickedOnEdgeOrVertex
    ? getVertexWithChangedCoordinates({
        prevCoordinates,
        currentCoordinates,
      })
    : undefined;

  return { isClickedOnEdgeOrVertex, vertexWithChangedCoordinates };
};
export function calculatePolygonArea({ polygons, polygonId, google }) {
  const fallBackValue = 0;
  const maxValueInSquareMeters = 10000;
  const squareMetersToSquareKilometersRatio = 10 ** -6;

  const polygon = polygons.find((polygon) => polygon.id === polygonId);
  const coordinates = polygon ? polygon.coordinates : null;

  if (coordinates) {
    const squareMeters =
      google.maps.geometry.spherical.computeArea(coordinates);

    const isValueDisplayedInSquareMeters =
      squareMeters <= maxValueInSquareMeters;

    const area = parseFloat(
      (isValueDisplayedInSquareMeters
        ? squareMeters
        : squareMeters * squareMetersToSquareKilometersRatio
      ).toFixed(2)
    );

    return isValueDisplayedInSquareMeters ? `${area} m²` : `${area} km²`;
  }

  return `${fallBackValue} m²`;
}
export function getPolygonOptionByName({ name, polygons, polygonId }) {
  const [, currentPolygon] = dropAndReturnElementById({
    id: polygonId,
    elements: polygons,
  });
  return currentPolygon.options[name];
}

export const isPointInPolygon = (point, polygon) => {
  const { lat, lng } = point;

  let isInside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i].lat, yi = polygon[i].lng;
    const xj = polygon[j].lat, yj = polygon[j].lng;
    const intersect = ((yi > lng) !== (yj > lng)) && (lat < (xj - xi) * (lng - yi) / (yj - yi) + xi);
    if (intersect) isInside = !isInside;
  }
  return isInside;
};