import { chunk } from "lodash";

export const deltaToBoxCoors = (deltaX, deltaY) => {
    const x = Math.min(...deltaX);
    const y = Math.min(...deltaY);
    const width = Math.max(...deltaX) - x;
    const height = Math.max(...deltaY) - y;
    return { x, y: y, width, height };
};

export const generateBounding = (p) => {
    const points = pointsToNodes(p);
    let deltaX = [];
    let deltaY = [];

    points.map((point) => {
        deltaX.push(point[0]);
        deltaY.push(point[1]);
        return null;
    });

    return deltaToBoxCoors(deltaX, deltaY);
};

export const pointsToNodes = (points) => chunk(points, 2);

export const getLineDistance = (x1, y1, x2, y2) => {
    return Math.hypot(x2 - x1, y2 - y1);
};

export const resetShape = (shape, data) => {
    shape.setAttrs({ x: 0, y: 0, rotation: 0, ...data });
};
export const rotatePoint = (pt, o, rotation) => {
    var angle = rotation * (Math.PI / 180); // Convert to radians

    var rotatedX =
        Math.cos(angle) * (pt.x - o.x) - Math.sin(angle) * (pt.y - o.y) + o.x;

    var rotatedY =
        Math.sin(angle) * (pt.x - o.x) + Math.cos(angle) * (pt.y - o.y) + o.y;

    return { x: rotatedX, y: rotatedY };
};

