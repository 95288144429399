import axios from "axios";
export const authService = {
  login,
  register,
  forgotPassword,
  logout,
  assingRole
};
const API = process.env.REACT_APP_API_URL
async function register(data) {
  return await axios.post(
    `${API}/auth/signUp`,
    data,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}
async function login(data) {
  return await axios.post(
    `${API}/auth/signIn`,
    data,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}
async function assingRole(data, token) {
  return await axios.post(
    `${API}/assingRole`,
    data,
    {
      headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token }
    }
  );
}

async function logout(token) {
  return await axios.post(
    `${API}/logout`,
    null,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    }
  );
}

async function forgotPassword(data) {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/forgot-password`,
    data,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}
