/* eslint-disable no-unused-expressions */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loggedIn: false,
    _id: '',
    email: "",
    user: "",
    access: "",
    profile: {},
    email_verified_at: false,
    timestamp: new Date().getTime(),
    role: []
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state, action) => {
            state.loggedIn = true
            state._id = action.payload.uid
            state.email = action.payload.email
            state.user = action.payload.name
            state.access = action.payload.token
            state.profile = action.payload.profile
            state.email_verified_at = action.payload.email_verified_at
            state.timestamp = new Date().getTime()
            state.role= action.payload.role
        },
        logout: (state, action) => {
            state.loggedIn = false
            state._id = ""
            state.email = ""
            state.user = ""
            state.access = ""
            state.profile = {}
            state.timestamp = null
            state.email_verified_at = false,
            state.role=[]
        },
        setRole: (state, action) => {
            state.role = action.payload.role
        },
        user: (state, action) => {
            state;
        }
    }
});

export const { login, logout, setRole } = authSlice.actions;
export const getAuth = (state) => state.auth
export const getToken = (state) => state.auth.access
export const isLoggedIn = (state) => state.auth.loggedIn
export const getRole = (state) => state.auth.role
export default authSlice.reducer;
