import { Outlet, Navigate } from 'react-router-dom'
import AuthLayout from "../components/Layouts/AuthLayout";
import { getToken, isLoggedIn } from "../store/slices/auth"
import React from 'react';
import { useSelector } from 'react-redux';
const ProtectedRoutes = () => {
    const token = useSelector(getToken);
    const isUserLoggedIn = useSelector(isLoggedIn);
    const isAuthenticated = isUserLoggedIn && token;
    return (
        isAuthenticated ? (
            <AuthLayout>
                <React.Suspense fallback={<div className="loading-loader-section"></div>}>
                    <Outlet />
                </React.Suspense>
            </AuthLayout>
        ) : (
            <Navigate to="/" replace />
        )
    );
}

export default ProtectedRoutes;