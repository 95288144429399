import React, { useEffect } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useSetupContext } from "../../context/SetupContext";
import { isEmptyObject, isPointInPolygon } from "../../_helpers";
import { nanoid } from "nanoid";
import { buildingService } from "../../_services";
import { useSelector } from "react-redux";
import { getToken } from "../../store/slices/auth";
import { setBuilding } from "../../store/slices/setup";
import { useDispatch } from "react-redux";
const DragNDropHolder = ({ children }) => {
  const token = useSelector(getToken);
  const dispatch = useDispatch();
  const {
    currentSite,
    setStructureMapMarkers,
    structureMarkers,
    structureMapMarkers,
    polygonLatLongTracker,
    setPolygonLatLongTracker,
  } = useSetupContext();
  const onDragEnd = async (result) => {
    if (isEmptyObject(currentSite)) {
      alert("There is no active site available to place the structure.");
      return;
    }

    const { destination, source } = result;
    if (!destination || destination.droppableId !== "map") return;
    if (!currentSite?.polygon_marker) return;

    if (
      isPointInPolygon(
        polygonLatLongTracker,
        currentSite?.polygon_marker?.coordinates
      )
    ) {
      const droppedMarker = structureMarkers[source.index];
      const marker = {
        id: nanoid(),
        position: polygonLatLongTracker,
        svg: droppedMarker.svg,
      };
      const buildingObject = {
        name: "Demo Building",
        description: "Building details needed",
        image: null,
        site_id: parseInt(currentSite?.id),
        longitude: marker.position.lng,
        latitude: marker.position.lat,
        floor_qty: 0,
        marker: JSON.stringify(marker),
      };

      try {
        const response = await buildingService.createBuilding(
          buildingObject,
          token
        );
        buildingObject.marker = JSON.parse(buildingObject.marker);
        if (response.data.code === 201) {
          let building = response?.data?.data;
          setStructureMapMarkers((prevMarkers) => [...prevMarkers, building]);
          dispatch(setBuilding({ building: building, site: currentSite }));
        }
      } catch (error) {
        console.error("Error creating building:", error);
        alert("Failed to create building. Please try again.");
      }
    } else {
      setPolygonLatLongTracker({});
      alert(
        "The selected site is not within the polygon. Please select a site within the polygon."
      );
    }
  };
  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>{children}</DragDropContext>
    </>
  );
};

export default DragNDropHolder;
