/* global google */
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedCategory,
  setCurrentMode,
  getActiveProject,
  getCurrentMode,
  setCanOpenLeftSidePanel,
  setActiveMarker,
  getActiveMarker,
  setClearActiveProject,
} from "../../store/slices/setup";
import { MODE } from "../../_helpers";
import React from "react";
import { useSetupContext } from "../../context/SetupContext";
import { useViewer } from "../../context/ViewerContext";

const ProjectListing = () => {
  const dispatch = useDispatch();
  const setupMode = useSelector(getCurrentMode);
  const selectedCategory = useSelector(getSelectedCategory);
  const activeMarker = useSelector(getActiveMarker);
  const projectsData = useSelector(getActiveProject);
  const { handelAddDefaultLayer } = useViewer();
  const {
    setIsSetupModalVisible,
    setCurrentSite,
    setCurrentBuilding,
    setCurrentFloor,
    mapRef,
    activeFloorIndex,
    setActiveFloorIndex,
    activeBuildingIndex,
    setActiveBuildingIndex,
    activeSiteIndex,
    setActiveSiteIndex,
    showSites,
    setShowSites,
    directSelectedFloorId,
    setStructureMapMarkers,
    setPolygons,
    handleToggleToProjectListingMode
  } = useSetupContext();
  const toogleProjectRoot = (index) => {
    const newShowSites = new Array(projectsData.length).fill(false);
    newShowSites[index] = !showSites[index];
    if (newShowSites[index]) {
      setActiveSiteIndex([-1, -1]);
    }
    if (setupMode?.mode === MODE.ACTIVATE_VIEWER) {
      const userConfirmed = window.confirm(
        "You are currently in viewer mode. This action will switch you to google map. Do you want to continue?"
      );
      if (userConfirmed) {
        setActiveFloorIndex([-1, -1, -1, -1]);
        setActiveBuildingIndex([-1, -1, -1]);
        setActiveSiteIndex([-1, -1]);
        setCurrentBuilding({});
        setCurrentSite({});
        setStructureMapMarkers([]);
        setPolygons([]);
        dispatch(
          setCurrentMode({
            mode: MODE.ACTIVATE_GOOGLE_MAP,
            viewer: MODE.ACTIVATE_GOOGLE_MAP_VIEW,
            prevMode: setupMode?.mode,
          })
        );
      }
      return;
    }
    setStructureMapMarkers([]);
    setPolygons([]);
    setShowSites(newShowSites);
  };

  const toggleSelectedSite = (projectIndex, siteIndex, site) => {
    const newActiveSiteIndex = [projectIndex, siteIndex];
    if (
      activeSiteIndex[0] === projectIndex &&
      activeSiteIndex[1] === siteIndex
    ) {
      setCurrentSite({});
      setActiveSiteIndex([-1, -1]);
      setStructureMapMarkers([]);
      setPolygons([]);
      dispatch(
        setCurrentMode({
          mode: MODE.ACTIVATE_GOOGLE_MAP,
          viewer: MODE.ACTIVATE_GOOGLE_MAP_VIEW,
        })
      );
    } else {
      let _site = projectsData[projectIndex]?.sites[siteIndex];
      dispatch(setActiveMarker({ marker: null }));
      handelAddDefaultLayer(_site?.id);
      setCurrentSite(_site);
      setActiveSiteIndex(newActiveSiteIndex);
      if (mapRef.current) {
        if (_site.polygon_marker !== null) {
          let coords = _site.polygon_marker.coordinates;
          const bounds = new google.maps.LatLngBounds();
          coords.forEach((coord) =>
            bounds.extend(new google.maps.LatLng(coord.lat, coord.lng))
          );
          mapRef.current.fitBounds(bounds);
        }
      }
    }
  };

  const toggleSelectedBuilding = (projectIndex, siteIndex, buildingIndex) => {
    const newActiveBuildingIndex = [projectIndex, siteIndex, buildingIndex];
    if (
      activeBuildingIndex[0] === projectIndex &&
      activeBuildingIndex[1] === siteIndex &&
      activeBuildingIndex[2] === buildingIndex
    ) {
      setActiveBuildingIndex([-1, -1, -1]);
      setCurrentBuilding({});
    } else {
      setActiveBuildingIndex(newActiveBuildingIndex);
      setCurrentBuilding(
        projectsData[projectIndex]?.sites[siteIndex]?.buildings[buildingIndex]
      );
    }
  };
  const toggleSelectedFloor = (
    projectIndex,
    siteIndex,
    buildingIndex,
    floorIndex
  ) => {
    const newActiveFloorIndex = [
      projectIndex,
      siteIndex,
      buildingIndex,
      floorIndex,
    ];
    if (
      activeFloorIndex[0] === projectIndex &&
      activeFloorIndex[1] === siteIndex &&
      activeFloorIndex[2] === buildingIndex &&
      activeFloorIndex[3] === floorIndex
    ) {
      setActiveFloorIndex([-1, -1, -1, -1]);
    } else {
      setActiveFloorIndex(newActiveFloorIndex);
      setCurrentFloor(
        projectsData[projectIndex]?.sites[siteIndex]?.buildings[buildingIndex]
          ?.floors[floorIndex]
      );
    }
  };
  const handelAddNewSite = (project) => {
    setIsSetupModalVisible((prevState) => !prevState);
  };

  // const handelAddNewBuilding = (site) => {
  //   dispatch(
  //     setCurrentMode({
  //       mode: MODE.NEW_BUILDING_SETUP_MODE,
  //       props: { previewImage: { show: false, imageObject: {} } },
  //     })
  //   );
  //   setCurrentSite(site);
  //   setIsSetupModalVisible((prevState) => !prevState);
  // };

  // const handelAddNewFloor = (building, site) => {
  //   dispatch(
  //     setCurrentMode({
  //       mode: MODE.NEW_FLOOR_SETUP_MODE,
  //       props: { previewImage: { show: false, imageObject: {} } },
  //     })
  //   );
  //   setCurrentBuilding(building);
  //   setCurrentSite(site);
  //   setIsSetupModalVisible((prevState) => !prevState);
  // };

  return (
    <div className="side__tab__listing__section">
      <>
        {projectsData.length > 0 ? (
          <>
            {/**
             *        <div className="side__searchbar">
              <div className="side__wrapper">
                <input
                  placeholder="Search"
                  type="search"
                  className="side__search__input"
                />
                <img src="./images/icons/search.svg" />
              </div>
            </div>
             * 
             */}
     
            <div
              className="side__tab__list__header__inner"
              onClick={handleToggleToProjectListingMode}
            >
              {setupMode?.mode !== MODE.INITIAL_PROJECT_SELECTION && (
                <i className="ri-arrow-left-double-line ri-lg"></i>
              )}
              <h5>{selectedCategory?.name}</h5>
            </div>
            <ul>
              {projectsData.map((project, projectIndex) => (
                <li key={project.id}>
                  <span
                    onClick={() => toogleProjectRoot(projectIndex)}
                    className={showSites[projectIndex] ? "active" : ""}
                  >
                    {showSites[projectIndex] ? (
                      <img src="./images/icons/sub-folder.svg" alt="" />
                    ) : (
                      <img src="./images/icons/add-folder.svg" alt="" />
                    )}
                    <p>{project.name}</p>
                    <i
                      className={`ri-arrow-${
                        showSites[projectIndex] ? "down" : "right"
                      }-s-line ri-xl`}
                    ></i>
                  </span>
                  {showSites[projectIndex] && (
                    <ul>
                      {project.sites?.map((site, siteIndex) => (
                        <li key={site.id || siteIndex}>
                          <span
                            className={
                              activeSiteIndex[0] === projectIndex &&
                              activeSiteIndex[1] === siteIndex
                                ? "active"
                                : ""
                            }
                            onClick={() =>
                              toggleSelectedSite(projectIndex, siteIndex, site)
                            }
                          >
                            {activeSiteIndex[0] === projectIndex &&
                            activeSiteIndex[1] === siteIndex ? (
                              <img src="./images/icons/sub-folder.svg" alt="" />
                            ) : (
                              <img src="./images/icons/add-folder.svg" alt="" />
                            )}

                            <p> {site?.name}</p>
                            <i
                              className={`ri-arrow-${
                                activeSiteIndex[0] === projectIndex &&
                                activeSiteIndex[1] === siteIndex
                                  ? "down"
                                  : "right"
                              }-s-line ri-xl`}
                            ></i>
                          </span>
                          {activeSiteIndex[0] === projectIndex &&
                            activeSiteIndex[1] === siteIndex && (
                              <ul>
                                {site.buildings?.map(
                                  (building, buildingIndex) => (
                                    <li key={building.id || buildingIndex}>
                                      <span
                                        className={
                                          (activeBuildingIndex[0] ===
                                            projectIndex &&
                                            activeBuildingIndex[1] ===
                                              siteIndex &&
                                            activeBuildingIndex[2] ===
                                              buildingIndex) ||
                                          activeMarker?.id === building.id
                                            ? "active"
                                            : ""
                                        }
                                        onClick={() =>
                                          toggleSelectedBuilding(
                                            projectIndex,
                                            siteIndex,
                                            buildingIndex
                                          )
                                        }
                                      >
                                        {activeBuildingIndex[0] ===
                                          projectIndex &&
                                        activeBuildingIndex[1] === siteIndex &&
                                        activeBuildingIndex[2] ===
                                          buildingIndex ? (
                                          <img
                                            src="./images/icons/sub-folder.svg"
                                            alt=""
                                          />
                                        ) : (
                                          <img
                                            src="./images/icons/add-folder.svg"
                                            alt=""
                                          />
                                        )}

                                        <p>{building.name}</p>
                                        <i
                                          className={`ri-arrow-${
                                            activeBuildingIndex[0] ===
                                              projectIndex &&
                                            activeBuildingIndex[1] ===
                                              siteIndex &&
                                            activeBuildingIndex[2] ===
                                              buildingIndex
                                              ? "down"
                                              : "right"
                                          }-s-line ri-xl`}
                                        ></i>
                                      </span>
                                      {activeBuildingIndex[0] ===
                                        projectIndex &&
                                        activeBuildingIndex[1] === siteIndex &&
                                        activeBuildingIndex[2] ===
                                          buildingIndex && (
                                          <ul>
                                            {building.floors?.length > 0 &&
                                              building.floors.map(
                                                (floor, floorIndex) => (
                                                  <li key={floorIndex}>
                                                    <span
                                                      className={
                                                        (activeFloorIndex[0] ===
                                                          projectIndex &&
                                                          activeFloorIndex[1] ===
                                                            siteIndex &&
                                                          activeFloorIndex[2] ===
                                                            buildingIndex &&
                                                          activeFloorIndex[3] ===
                                                            floorIndex) ||
                                                        directSelectedFloorId ===
                                                          floor.floor_polygon_id
                                                          ? "active"
                                                          : ""
                                                      }
                                                      onClick={() =>
                                                        toggleSelectedFloor(
                                                          projectIndex,
                                                          siteIndex,
                                                          buildingIndex,
                                                          floorIndex
                                                        )
                                                      }
                                                    >
                                                      {`${
                                                        floor.name ??
                                                        "floor_" +
                                                          (floorIndex + 1)
                                                      }`}
                                                      <img
                                                        onClick={() =>
                                                          toggleSelectedFloor(
                                                            projectIndex,
                                                            siteIndex,
                                                            buildingIndex,
                                                            floorIndex
                                                          )
                                                        }
                                                        src={
                                                          floor.image_full_url
                                                        }
                                                        alt=""
                                                        style={{
                                                          maxWidth: "50%",
                                                          height: "auto",
                                                        }}
                                                      />
                                                    </span>
                                                  </li>
                                                )
                                              )}
                                          </ul>
                                        )}
                                    </li>
                                  )
                                )}
                              </ul>
                            )}
                        </li>
                      ))}
                      <li>
                        <span
                          className="new--create"
                          onClick={() => {
                            handelAddNewSite(project);
                          }}
                        >
                          <i className="ri-add-circle-line ri-xl"></i> Create
                          New Site
                        </span>
                      </li>
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </>
        ) : (
          <div className="side__tab__list__header__inner">
            <h5>No project has been selected</h5>
          </div>
        )}
      </>
    </div>
  );
};

export default ProjectListing;
