import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    isLoading: false,
    route: null
};

const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        setLoading(state, action) {
            state.isLoading = Boolean(action.payload.isLoading);
            state.route = action.payload.route || null;
        },
    },
});
export const { setLoading } = loadingSlice.actions;
export const getLoading = (state) => state.loading.isLoading;
export const getRoute = (state) => state.loading.route;
export default loadingSlice.reducer;
