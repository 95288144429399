/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentMode,
  getCurrentMode,
  getActiveProject,
  setClearActiveProject,
  setCanOpenLeftSidePanel,
  setActiveMarker,
} from "../store/slices/setup";
import { MODE } from "../_helpers";
import { miscellaneousService } from "../_services";
import { getToken } from "../store/slices/auth";
const SetupContext = createContext();
export const SetupProvider = ({ children }) => {
  const dispatch = useDispatch();
  const projectsData = useSelector(getActiveProject);
  const [showSites, setShowSites] = React.useState(
    projectsData && projectsData?.map((_, index) => index === 0)
  );
  const [currentMapTool, setCurrentMapTool] = useState(null);
  const [zoomLevelMap, setZoomLevelMap] = useState(16);
  const [categories, setCategories] = useState([]);
  const [isSetupModalVisible, setIsSetupModalVisible] = useState(false);
  const [isShowProjectForm, setShowProjectForm] = useState(false);
  const [isProjectFormEdit, setIsProjectFormEdit] = useState(false);
  const [editProject, setEditProject] = useState({});
  const [isSiteFormEdit, setIsSiteFormEdit] = useState(false);
  const [editSite, setEditSite] = useState({});
  const [currentProject, setCurrentProject] = useState({});
  const [currentSite, setCurrentSite] = useState({});
  const [currentBuilding, setCurrentBuilding] = useState({});
  const [currentFloor, setCurrentFloor] = useState({});
  const [structureMapMarkers, setStructureMapMarkers] = useState([]);
  const [polygonLatLongTracker, setPolygonLatLongTracker] = useState({});
  const [selectedBuildingMarker, setSelectedBuildingMarker] = useState(null);
  const setupMode = useSelector(getCurrentMode);
  const [directSelectedFloorId, setDirectSelectedFloorId] = useState(null);
  const [isClickOutsideDisabled, setIsClickOutsideDisabled] = useState(false);
  const [buildingMarkerContextMenu, setBuildingMarkerContextMenu] =
    useState(null);
  const [polygonCardData, setPolygonCardData] = React.useState(null);
  const [undoData, setUndoData] = React.useState(null);
  const [polygons, setPolygons] = React.useState([]);
  const [originalMarkerPositions, setOriginalMarkerPositions] = useState({});
  const [activeSiteIndex, setActiveSiteIndex] = React.useState([-1, -1]);
  const [activeBuildingIndex, setActiveBuildingIndex] = React.useState([
    -1, -1, -1,
  ]);
  const [activeFloorIndex, setActiveFloorIndex] = React.useState([
    -1, -1, -1, -1,
  ]);
  const mapRef = useRef(null);
  const token = useSelector(getToken);
  const [structures, setStructures] = useState([]);
  const googleMapsApiKey = useMemo(
    () => "AIzaSyC6oxXm0xvNSLA_GyWPnTfx5ezf7SF0eSw",
    []
  );
  useEffect(() => {
    const fetchStructure = async () => {
      try {
        const response = await miscellaneousService.getStructures(token);
        setStructures(response || []);
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    fetchStructure();
  }, []);
  const structureMarkers = [
    {
      id: "building-001",
      name: "Structure One",
      svg: "./images/assets/structures/1.svg",
    },
    {
      id: "building-002",
      name: "Structure Two",
      svg: "./images/assets/structures/2.svg",
    },
    {
      id: "building-003",
      name: "Structure Three",
      svg: "./images/assets/structures/3.svg",
    },
    {
      id: "building-004",
      name: "Structure Four",
      svg: "./images/assets/structures/4.svg",
    },
    {
      id: "building-005",
      name: "Structure Five",
      svg: "./images/assets/structures/5.svg",
    },
    {
      id: "building-006",
      name: "Structure Six",
      svg: "./images/assets/structures/6.svg",
    },
  ];
  const handelClearFloorSelection = () => {
    setActiveFloorIndex([-1, -1, -1, -1]);
    setCurrentFloor({});
    setDirectSelectedFloorId(null);
  };
  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  const handelCreateNewProject = () => {
    setShowProjectForm(true);
  };
  const handelCloseFormModal = (reqestedMode) => {
    setShowProjectForm(false);
    setIsSetupModalVisible(false);
    setEditProject({});
    setIsProjectFormEdit(false);
    setEditSite({});
    setIsSiteFormEdit(false);
    // if (
    //   setupMode?.mode === MODE.NEW_SITE_SETUP_MODE &&
    //   setupMode?.viewer === MODE.ACTIVATE_GOOGLE_MAP_VIEW
    // ) {
    //   dispatch(
    //     setCurrentMode({
    //       mode: MODE.ACTIVATE_GOOGLE_MAP,
    //       viewer: MODE.ACTIVATE_GOOGLE_MAP_VIEW,
    //     })
    //   );
    // }

    // if (
    //   setupMode?.mode === MODE.NEW_SITE_SETUP_MODE &&
    //   setupMode?.viewer === MODE.ACTIVATE_INITIAL_OVERLAY
    // ) {
    //   dispatch(
    //     setCurrentMode({
    //       mode: reqestedMode ? reqestedMode : setupMode?.props?.prevMode,
    //       prevMode: setupMode?.mode,
    //     })
    //   );
    // }
    // if (
    //   setupMode?.mode === MODE.NEW_PROJECT_SETUP_INTERNAL ||
    //   setupMode?.mode === MODE.INITIAL_POJECT_SELECTION
    // ) {
    //   dispatch(
    //     setCurrentMode({
    //       mode: reqestedMode ? reqestedMode : setupMode?.props?.prevMode,
    //       prevMode: setupMode?.mode,
    //     })
    //   );
    // }
  };

  const generateHeaderLabelingObject = (type) => {
    switch (type) {
      case "INITIAL_POJECT_SELECTION":
        return {
          title: "Cabling Installer Projects",
          subTitle: "Choose a Project",
        };
      case "Certification":
        return {
          title: "Certification is not available yet.",
          subTitle: "Coming Soon",
        };
      case "Labeling":
        return {
          title: "Labeling is not available yet.",
          subTitle: "Coming Soon",
        };
      default:
        return {
          title: "Cabling Installer Specialist",
          subTitle: "Choose a Category",
        };
    }
  };
  /**
   * MAP FUNCTIONS
   */
  const handleToggleToProjectListingMode = () => {
    dispatch(setActiveMarker({ marker: null }));
     dispatch(setCanOpenLeftSidePanel({ canOpenLeftSidePanel: MODE.NO }));
    dispatch(
      setCurrentMode({
        mode: MODE.INITIAL_POJECT_SELECTION,
        viewer: MODE.ACTIVATE_INITIAL_OVERLAY,
        props: { previewImage: { show: false, imageObject: {} } },
      })
    );
    dispatch(setClearActiveProject());
    
    setActiveFloorIndex([-1, -1, -1, -1]);
    setActiveBuildingIndex([-1, -1, -1]);
    setActiveSiteIndex([-1, -1]);
    setCurrentBuilding({});
    setCurrentSite({});
    setStructureMapMarkers([]);
    setPolygons([]);
  };
  return (
    <SetupContext.Provider
      value={{
        structureMapMarkers,
        setStructureMapMarkers,
        structureMarkers,
        categories,
        setCategories,
        isSetupModalVisible,
        setIsSetupModalVisible,
        isShowProjectForm,
        currentProject,
        setCurrentProject,
        setShowProjectForm,
        currentBuilding,
        setCurrentBuilding,
        currentSite,
        setCurrentSite,
        currentFloor,
        setCurrentFloor,
        handelCreateNewProject,
        handelCloseFormModal,
        generateHeaderLabelingObject,
        polygonLatLongTracker,
        setPolygonLatLongTracker,
        isEmptyObject,
        selectedBuildingMarker,
        setSelectedBuildingMarker,
        isClickOutsideDisabled,
        setIsClickOutsideDisabled,
        buildingMarkerContextMenu,
        setBuildingMarkerContextMenu,
        undoData,
        setUndoData,
        polygons,
        setPolygons,
        originalMarkerPositions,
        setOriginalMarkerPositions,
        polygonCardData,
        setPolygonCardData,
        currentMapTool,
        setCurrentMapTool,
        zoomLevelMap,
        setZoomLevelMap,
        mapRef,
        activeFloorIndex,
        setActiveFloorIndex,
        activeBuildingIndex,
        setActiveBuildingIndex,
        activeSiteIndex,
        setActiveSiteIndex,
        showSites,
        setShowSites,
        handelClearFloorSelection,
        directSelectedFloorId,
        setDirectSelectedFloorId,
        structures,
        googleMapsApiKey,
        isProjectFormEdit,
        setIsProjectFormEdit,
        editProject,
        setEditProject,
        isSiteFormEdit,
        setIsSiteFormEdit,
        editSite,
        setEditSite,
        handleToggleToProjectListingMode
      }} 
    >
      {children}
    </SetupContext.Provider>
  );
};

export const useSetupContext = () => useContext(SetupContext);
