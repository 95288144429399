import axios from "axios";
export const buildingService = {
  createBuilding,
  createFloor,
  updateBuildingMarker,
  updateBuilding,
  deleteBuilding,
  updateBuildingFloorMarkingLayers,
  deleteBuildingFloor,
  getFloorBypolygonId,
  updateFloor
};
const API = process.env.REACT_APP_API_URL;


async function createBuilding(data, token) {
  return await axios.post(`${API}/buildings/store`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  });
}

async function createFloor(data, token) {
  return await axios.post(`${API}/floors/store-floors`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  });
}
async function deleteBuildingFloor(data, token) {
  return await axios.post(`${API}/buildings/deleteBuildingFloor`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  });
}

async function updateBuildingMarker(data,token) {
  return await axios.post(`${API}/buildings/updateBuildingMarker`, data, {
    headers: { Authorization: "Bearer " + token },
  });
}
async function updateBuildingFloorMarkingLayers(data,token) {
  return await axios.post(`${API}/buildings/updateBuildingFloorMarkingLayers`, data, {
    headers: { Authorization: "Bearer " + token },
  });
}

  async function updateBuilding(data,token) {
    return await axios.post(`${API}/buildings/update`, data, {
      headers: { Authorization: "Bearer " + token },
    });
  }
  async function deleteBuilding(data,token) {
    return await axios.post(`${API}/buildings/delete`, data, {
      headers: { Authorization: "Bearer " + token },
    });
  }
  async function getFloorBypolygonId(token, id) {
    try {
      const response = await axios.get(`${API}/floors/get-floor-polygon-id`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        params: {
          floor_polygon_id: id
        },
      });
      return response;
    } catch (error) {
      console.error("Error while making GET request:", error);
      throw error;
    }
  }
  
  async function updateFloor(data,token) {
    return await axios.post(`${API}/floors/update-floors`, data, {
      headers: { Authorization: "Bearer " + token },
    });
  }