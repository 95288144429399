import axios from "axios";

export const handleRegularFileInputChange = (e, callback) => {
  const file = e.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = () => {
      const base64 = reader.result.split(",")[1];
      callback(base64);
    };
    reader.readAsDataURL(file);
  }
};

export const handleSvgFileInputChange = (e, callback) => {
  const file = e.target.files[0];
  if (file && file.type === "image/svg+xml") {
    const reader = new FileReader();
    reader.onload = () => {
      const svgString = reader.result;
      callback(svgString);
    };
    reader.readAsText(file);
  }
};

export const isSvgFile = (event) => {
  const file = event.target.files[0];
  return file && file.type === "image/svg+xml";
};

// Convert ArrayBuffer to Base64
const arrayBufferToBase64 = (arrayBuffer) => {
  const bytes = new Uint8Array(arrayBuffer);
  let binary = "";
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const handleImageUrlToBase64 = async (imageUrl, callback) => {
  try {
    if (!imageUrl) {
      return;
    }
    // Fetch the image as an array buffer
    const response = await axios.get(imageUrl, { responseType: "arraybuffer" });
    const arrayBuffer = response.data;

    // Convert the array buffer to a Base64 string
    const base64String = arrayBufferToBase64(arrayBuffer);

    // Get the MIME type from the response headers
    const mimeType = response.headers["content-type"];

    // Create the data URL
    const dataUrl = `data:${mimeType};base64,${base64String}`;

    // Call the callback function if provided
    if (callback && typeof callback === "function") {
      callback(dataUrl);
    }
  } catch (error) {
    console.error("Error converting image to Base64:", error);
  }
};