import { Toaster } from 'react-hot-toast';
import AppRouter from './router/';
const App = () => {
  return (
    <div className="App">
      <AppRouter />
      {/* Add the Toaster component */}
      <Toaster position="top-right" />
    </div>
  );
}
export default App;
