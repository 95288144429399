import React from "react";
import SideControlPanel from "../SideControlPanel";
const AuthLayout = ({ children }) => {
    return (
        <>
            <div className="main__wrapper">
                <header className="top__bar" id="top-header">
                </header>
                <div className="main__section">
                    <div className="sidebar__wrapper" id="sidebar-control-panel-wrapper">
                    </div>
                    <div className="main__container__wrapper">
                        <div className="main__container">
                            <div className="canvas__container">
                                {children}
                                <div className="right-sidebar" id="right-side-tab-listing-section">
                                    <SideControlPanel />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AuthLayout;