import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLoading, getRoute, setLoading } from '../store/slices/loading';
import { Triangle } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

const GlobalLoader = () => {
    const [isVisible, setIsVisible] = useState(false);
    const isLoading = useSelector(getLoading);
    const dispatch = useDispatch();
    const route = useSelector(getRoute);
    const navigate = useNavigate();
    useEffect(() => {
        if (isLoading) {
            setIsVisible(true);
            const timeoutId = setTimeout(() => {
                dispatch(setLoading({isLoading:false,route:null}))
                setIsVisible(false);
                if (route) {
                    navigate(route);
                }
            }, 2000);
            return () => clearTimeout(timeoutId);
        }
    }, [isLoading, route, dispatch, navigate]);
    return (
        <div className="global-loader" style={{ display: isVisible ? 'flex' : 'none' }}>
            <Triangle
                visible={true}
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>
    );
};

export default GlobalLoader;
