import toast from "react-hot-toast";
export const showValidationErrorsToast = (error) => {
    if (error.response && error.response.data && error.response.data.validation_error) {
        const validationErrors = error.response.data.validation_error;
        Object.keys(validationErrors).forEach((key) => {
            validationErrors[key].forEach((errorMessage) => {
                toast.error(errorMessage);
            });
        });
    } else {
        let errorMessage = "An error occurred. Please try again.";
        if (error.response && error.response.data && error.response.data.message) {
            errorMessage = error.response.data.message;
        } else if (error.message) {
            errorMessage = error.message;
        }
        toast.error(errorMessage);
    }
};

export const showSuccessToast = (message) => {
    toast.success(message);
};

export const showErrorToast = (message) => {
    toast.error(message);
};

export const showInfoToast = (message) => {
    toast(message, {
        icon: 'ℹ️',
      });
};


//"No projects found in this category. Would you like to add a new project?"