/* eslint-disable jsx-a11y/alt-text */
import React from "react";
const SiteModal = ({ children, headerTitle, isOpen, onClose }) => {
  if (!isOpen) return null;
  return (
    <div className="site__modal__wrapper">
      <div className="site__modal">
        <div className="site__modal__header">
          <div className="site__modal__header__content">
            <h4>{headerTitle}</h4>
            <span className="site__modal__close__btn" onClick={onClose}>
              <i className="ri-close-circle-line ri-xl"></i>
            </span>
          </div>
        </div>
        <div className="site__modal__content">{children}</div>
      </div>
      <div className="site__modal">
        <div className="site__modal__header">
          <div className="site__modal__header__content">
            <span className="site__modal__close__btn" onClick={onClose}>
              <i className="ri-close-circle-line ri-xl"></i>
            </span>
          </div>
        </div>
        <div className="site__modal__content">
          <div className="site__modal__inner__content">
            <img className="socket__image" src="./images/socket.png" />
            <div className="inline__tab__section">
              <ul>
                <li className="inline__tab__active">Properties</li>
                <li>Setting</li>
                <li>Effect</li>
                <li>Interactive</li>
              </ul>
              <div className="inline__tab__content">
                <div className="inline__tab__content__item">
                  <div className="input__label__box">
                    <label>ID</label>
                    <div className="input__label__input_box">
                      <h6>240000000003451</h6>
                      <img src="./images/barcode.png" />
                    </div>
                  </div>
                  <div className="input__label__box">
                    <label>Name</label>
                    <div className="input__label__input_box">
                      <input
                        type="text"
                        className="form__control"
                        value="1-1-01F39"
                      />
                      <i className="ri-close-large-line ri-md"></i>
                    </div>
                  </div>
                  <div className="input__label__box">
                    <label>Model</label>
                    <div className="input__label__input_box">
                      <input
                        type="text"
                        className="form__control"
                        value="GP070-1UD24-65BK"
                      />
                      <i className="ri-close-large-line ri-md"></i>
                    </div>
                  </div>
                  <div className="input__label__box">
                    <label>Layer</label>
                    <div className="input__label__input_box">
                      <input type="text" className="form__control" value="" />
                      <i className="ri-close-large-line ri-md"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SiteModal;
