import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useSetupContext } from "../context/SetupContext";

const StructureList = () => {
  const { structures } = useSetupContext();
  return (
    <Droppable droppableId="structures">
      {(provided) => (
        <div>
          <div className="gird--item--section" ref={provided.innerRef} {...provided.droppableProps}>
            {structures.map((structure, index) => (
              <Draggable key={structure.id} draggableId={String(structure.id)} index={index}>
                {(provided) => (
                  <div className="gird--item">
                    <div className="gird--item-content">
                      <img
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        src={structure.image_full_url}
                        alt={`Marker ${structure.id}`}
                      />
                      <h6>{structure.name}</h6>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
          </div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default StructureList;
